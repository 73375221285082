import classNames from "classnames";
import { Link } from "react-router-dom";
import AlpsCoffee from "../assets/svg/alps_coffee.svg?react";
import Brandnamic from "../assets/svg/brandnamic.svg?react";
import Forst from "../assets/svg/forst.svg?react";
import Hgj from "../assets/svg/hgj.svg?react";
import Hotelfabrik from "../assets/svg/hotelfabrik.svg?react";
import Senoner from "../assets/svg/senoner.svg?react";
import Winestore from "../assets/svg/winestore.svg?react";
import WoerndleInterservice from "../assets/svg/woerndle_interservice.svg?react";
import { Partner } from "../types/types";
import styles from "./Footer.module.css";
import Icon from "./Icon";

const partnerList: Partner[] = [
  {
    icon: WoerndleInterservice,
    url: "https://portal.woerndle.it/home",
  },
  {
    icon: Brandnamic,
    url: "https://www.brandnamic.com/",
  },
  {
    icon: Senoner,
    url: "http://www.senoner.net/",
  },
  {
    icon: Forst,
    url: "https://www.forst.it/",
  },
  {
    icon: Winestore,
    url: "https://weindiele.com/",
  },
  {
    icon: Hotelfabrik,
    url: "https://hotelfabrik.it/",
  },
  {
    icon: AlpsCoffee,
    url: "https://www.alps-coffee.it/",
  },
];

interface Props {
  showPartner?: boolean;
}

const Footer = ({ showPartner = true }: Props) => (
  <footer className={classNames({ [styles.footerIcon]: showPartner })}>
    {showPartner && (
      <div className={styles.partnerList}>
        {partnerList.map(({ icon, url }) => (
          <div className={styles.partnerItem} key={url}>
            <a href={url} target="_blank">
              <Icon glyph={icon} className={styles.partnerIcon} />
            </a>
          </div>
        ))}
      </div>
    )}
    <div className={styles.contact}>
      <p className={styles.contactTitle}>
        <strong>Hoteliers- und Gastwirtejugend (HGJ)</strong>
      </p>
      <p className={styles.contactText}>Schlachthofstraße 59 | I-39100 Bozen</p>
      <p className={styles.contactText}>
        T: <a href="tel:+390471317999">0471 31 79 99</a> | M:{" "}
        <a href="tel:+393357267817">335 726 78 17</a>
      </p>
      <p className={styles.contactText}>
        <a href="mailto:info@hgj.it">info@hgj.it</a> |{" "}
        <a href="https://www.hgj.it" target="_blank">
          www.hgj.it
        </a>
      </p>
      <p className={styles.contactText}>St: 80024040216 | MwSt: 02276370216</p>
      <p className={styles.contactText}>
        <Link to="/impressum">Impressum</Link> |{" "}
        <Link to="/privacy">Privacy</Link>
      </p>
    </div>
    <div className={styles.logoWrapper}>
      <Icon glyph={Hgj} className={styles.logoIcon} />
    </div>
  </footer>
);

export default Footer;
